import { Currency, ETHER, Token } from "fortcake-sdk";
import { BinanceIcon } from "fortcake-uikit-v2";
import React, { useMemo } from "react";
import styled from "styled-components";
import useHttpLocations from "../../hooks/useHttpLocations";
import { WrappedTokenInfo } from "../../state/lists/hooks";
import getTokenLogoURL from "../../utils/getTokenLogoURL";
import Logo from "./Logo";
import { ExtendedToken } from "../SearchModal/CurrencySearch";

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

export default function CurrencyLogo({
  currency,
  size = "24px",
  style,
}: {
  currency?: Currency | ExtendedToken;
  size?: string;
  style?: React.CSSProperties;
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          ...uriLocations,
          `images/coins/${currency.symbol}.png`,
          getTokenLogoURL(currency.address),
        ];
      } else if (currency instanceof ExtendedToken) {
        return [
          `data:image/png;base64,${currency.logo}`,
          getTokenLogoURL(currency.address),
        ];
      } else {
        return [getTokenLogoURL(currency.address)];
      }
    }
    return [];
  }, [currency, uriLocations]);

  if (currency === ETHER) {
    return <BinanceIcon width={size} style={style} />;
  }

  return (
    <StyledLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  );
}
