import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CurrencyAmount, JSBI, Trade } from "fortcake-sdk";
import {
  Button,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  IconButton,
  useMatchBreakpoints,
  ArrowUpDownIcon,
  Link,
} from "fortcake-uikit-v2";
import { useIsTransactionUnsupported } from "../../hooks/Trades";
import { useTranslation } from "../../contexts/Localization";
import SwapWarningTokens from "../../config/constants/swapWarningTokens";
import UnsupportedCurrencyFooter from "../../components/UnsupportedCurrencyFooter";
import AddressInputPanel from "./components/AddressInputPanel";
import { GreyCard } from "../../components/Card";
import Column, { AutoColumn } from "../../components/Layout/Column";
import ConfirmSwapModal from "./components/ConfirmSwapModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import { AutoRow, RowBetween } from "../../components/Layout/Row";
import AdvancedSwapDetailsDropdown from "./components/AdvancedSwapDetailsDropdown";
import confirmPriceImpactWithoutFee from "./components/confirmPriceImpactWithoutFee";
import {
  Text,
  ArrowWrapper,
  SwapCallbackError,
  Wrapper,
} from "./components/styleds";
import TradePrice from "./components/TradePrice";
import ProgressSteps from "./components/ProgressSteps";
import { AppBody } from "../../components/App";
import ConnectWalletButton from "../../components/ConnectWalletButton";

import { INITIAL_ALLOWED_SLIPPAGE } from "../../config/constants";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import { Field } from "../../state/swap/actions";

import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
} from "../../state/user/hooks";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";
import CircleLoader from "../../components/Loader/CircleLoader";
import SwapWarningModal from "./components/SwapWarningModal";
import { StyledInputCurrencyWrapper, StyledSwapContainer } from "./styles";
import CurrencyInputHeader from "./components/CurrencyInputHeader";
import { useParams } from "react-router-dom";
import { useCurrency } from "../../hooks/Tokens";

const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) =>
    theme.isDark ? theme.colors.primary : theme.colors.textSubtle2};
`;

const SwitchIconButton = styled(IconButton)`
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  .icon-up-down {
    display: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    .icon-down {
      display: none;
      fill: white;
    }
    .icon-up-down {
      display: block;
      fill: white;
    }
  }
`;

// Add this new styled component near the top of the file
const AlertWrapper = styled(Box)`
  background-color: #56c9ac;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;
`;

export default function Swap() {
  const { outputCurrency: outputCurencyId } = useParams();

  const outputCurrency = useCurrency(outputCurencyId);

  useDefaultsFromURLSearch();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();

  // /************************ not needed ************************/

  // const [isChartExpanded, setIsChartExpanded] = useState(false)
  // const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  // const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)
  // /************************ not needed ************************/

  // useEffect(() => {
  //   setUserChartPreference(isChartDisplayed)
  // }, [isChartDisplayed, setUserChartPreference])

  // token warning stuff
  // const [loadedInputCurrency, loadedOutputCurrency] = [
  //   useCurrency(loadedUrlParams?.inputCurrencyId),
  //   useCurrency(loadedUrlParams?.outputCurrencyId),
  // ]
  // const urlLoadedTokens: Token[] = useMemo(
  //   () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
  //   [loadedInputCurrency, loadedOutputCurrency],
  // )

  // dismiss warning if all imported tokens are in active lists
  // const defaultTokens = useAllTokens()
  // const importTokensNotInDefault =
  //   urlLoadedTokens &&
  //   urlLoadedTokens.filter((token: Token) => {
  //     return !(token.address in defaultTokens)
  //   })

  const { account } = useActiveWeb3React();

  // for expert mode
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();

  // Price data
  // const {
  //   [Field.INPUT]: { currencyId: inputCurrencyId },
  //   [Field.OUTPUT]: { currencyId: outputCurrencyId },
  // } = useSwapState()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  // const singleTokenPrice = useSingleTokenSwapInfo()

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const [singleHopOnly] = useUserSingleHopOnly();

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
    });
  }, [attemptingTxn, swapErrorMessage, trade, txHash]);

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null);
  const [onPresentSwapWarningModal] = useModal(
    <SwapWarningModal swapCurrency={swapWarningCurrency} onDismiss={() => {}} />
  );

  const shouldShowSwapWarning = (swapCurrency: any) => {
    const isWarningToken = Object.entries(SwapWarningTokens).find(
      (warningTokenConfig) => {
        const warningTokenData = warningTokenConfig[1];
        return swapCurrency.address === warningTokenData.address;
      }
    );
    return Boolean(isWarningToken);
  };

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency]);

  const handleInputSelect = useCallback(
    (inputCurrency: any) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      const showSwapWarning = shouldShowSwapWarning(inputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(inputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency: any) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      const showSwapWarning = shouldShowSwapWarning(outputCurrency);
      if (showSwapWarning) {
        setSwapWarningCurrency(outputCurrency);
      } else {
        setSwapWarningCurrency(null);
      }
    },

    [onCurrencySelection]
  );

  const swapIsUnsupported = useIsTransactionUnsupported(
    currencies?.INPUT,
    currencies?.OUTPUT
  );

  useEffect(() => {
    if (outputCurrency) {
      handleOutputSelect(outputCurrency);
    }
  }, [outputCurrency, handleOutputSelect]);

  // const [onPresentImportTokenWarningModal] = useModal(
  //   <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => console.log(1)} />,
  // )

  // useEffect(() => {
  //   if (importTokensNotInDefault.length > 0) {
  //     onPresentImportTokenWarningModal()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [importTokensNotInDefault.length])

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    "confirmSwapModal"
  );

  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      position="relative"
      style={{ minHeight: isMobile || isTablet ? "80vh" : "100vh" }}
    >
      <Flex flexDirection="column">
        <StyledSwapContainer $isChartExpanded={false}>
          <StyledInputCurrencyWrapper mt={0}>
            <AlertWrapper>
              <Text color="contrast" bold>
                Swap will be active after Pinksale. See{" "}
                <Link
                  href="https://x.com/fortcake"
                  external
                  color="contrast"
                  style={{ textDecoration: "underline" }}
                >
                  X
                </Link>{" "}
                or{" "}
                <Link
                  href="https://t.me/fortcake"
                  external
                  color="contrast"
                  style={{ textDecoration: "underline" }}
                >
                  Telegram
                </Link>{" "}
                for updates!
              </Text>
            </AlertWrapper>
            <AppBody>
              <CurrencyInputHeader
                title={t("Exchange")}
                subtitle={t("Trade tokens in an instant")}
                // setIsChartDisplayed={setIsChartDisplayed}
                // isChartDisplayed={isChartDisplayed}
              />
              <Wrapper id="swap-page">
                <AutoColumn gap="md">
                  <CurrencyInputPanel
                    label={
                      independentField === Field.OUTPUT && !showWrap && trade
                        ? t("From (estimated)")
                        : t("From")
                    }
                    value={formattedAmounts[Field.INPUT]}
                    showMaxButton={!atMaxAmountInput}
                    currency={currencies[Field.INPUT]}
                    onUserInput={handleTypeInput}
                    onMax={handleMaxInput}
                    onCurrencySelect={handleInputSelect}
                    otherCurrency={currencies[Field.OUTPUT]}
                    id="swap-currency-input"
                  />

                  <AutoColumn justify="space-between">
                    <AutoRow
                      justify={isExpertMode ? "space-between" : "center"}
                      style={{ padding: "0 1rem" }}
                    >
                      <SwitchIconButton
                        variant="light"
                        scale="sm"
                        onClick={() => {
                          setApprovalSubmitted(false); // reset 2 step UI for approvals
                          onSwitchTokens();
                        }}
                      >
                        <ArrowDownIcon
                          className="icon-down"
                          color={
                            currencies[Field.INPUT] && currencies[Field.OUTPUT]
                              ? "primary"
                              : "text"
                          }
                        />
                        <ArrowUpDownIcon
                          className="icon-up-down"
                          color={
                            currencies[Field.INPUT] && currencies[Field.OUTPUT]
                              ? "primary"
                              : "text"
                          }
                        />
                      </SwitchIconButton>
                      {recipient === null && !showWrap && isExpertMode ? (
                        <Button
                          variant="text"
                          id="add-recipient-button"
                          onClick={() => onChangeRecipient("")}
                        >
                          {t("+ Add a send (optional)")}
                        </Button>
                      ) : null}
                    </AutoRow>
                  </AutoColumn>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.OUTPUT]}
                    onUserInput={handleTypeOutput}
                    label={
                      independentField === Field.INPUT && !showWrap && trade
                        ? t("To (estimated)")
                        : t("To")
                    }
                    showMaxButton={false}
                    currency={currencies[Field.OUTPUT]}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    id="swap-currency-output"
                  />

                  {isExpertMode && recipient !== null && !showWrap ? (
                    <>
                      <AutoRow
                        justify="space-between"
                        style={{ padding: "0 1rem" }}
                      >
                        <ArrowWrapper clickable={false}>
                          <ArrowDownIcon width="16px" />
                        </ArrowWrapper>
                        <Button
                          variant="text"
                          id="remove-recipient-button"
                          onClick={() => onChangeRecipient(null)}
                        >
                          {t("- Remove send")}
                        </Button>
                      </AutoRow>
                      <AddressInputPanel
                        id="recipient"
                        value={recipient}
                        onChange={onChangeRecipient}
                      />
                    </>
                  ) : null}

                  {showWrap ? null : (
                    <AutoColumn gap="8px" style={{ padding: "0 16px" }}>
                      {Boolean(trade) && (
                        <RowBetween align="center">
                          <Label>{t("Price")}</Label>
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                      {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                        <RowBetween align="center">
                          <Label>{t("Slippage Tolerance")}</Label>
                          <Text $contrast={2} bold>
                            {allowedSlippage / 100}%
                          </Text>
                        </RowBetween>
                      )}
                    </AutoColumn>
                  )}
                </AutoColumn>
                <Box mt="1rem">
                  {swapIsUnsupported ? (
                    <Button width="100%" disabled mb="4px">
                      {t("Unsupported Asset")}
                    </Button>
                  ) : !account ? (
                    <ConnectWalletButton width="100%" />
                  ) : showWrap ? (
                    <Button
                      width="100%"
                      disabled={Boolean(wrapInputError)}
                      onClick={onWrap}
                    >
                      {wrapInputError ??
                        (wrapType === WrapType.WRAP
                          ? "Wrap"
                          : wrapType === WrapType.UNWRAP
                            ? "Unwrap"
                            : null)}
                    </Button>
                  ) : noRoute && userHasSpecifiedInputOutput ? (
                    <GreyCard style={{ textAlign: "center" }}>
                      <Text mb="4px" $customColor="primary">
                        {t("Insufficient liquidity for this trade.")}
                      </Text>
                      {singleHopOnly && (
                        <Text $customColor="primary" mb="4px">
                          {t("Try enabling multi-hop trades.")}
                        </Text>
                      )}
                    </GreyCard>
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <Button
                        variant={
                          approval === ApprovalState.APPROVED
                            ? "success"
                            : "primary"
                        }
                        onClick={approveCallback}
                        disabled={
                          approval !== ApprovalState.NOT_APPROVED ||
                          approvalSubmitted
                        }
                        width="48%"
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            {t("Enabling")} <CircleLoader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted &&
                          approval === ApprovalState.APPROVED ? (
                          t("Enabled")
                        ) : (
                          t("Enable %asset%", {
                            asset: currencies[Field.INPUT]?.symbol ?? "",
                          })
                        )}
                      </Button>
                      <Button
                        variant={
                          isValid && priceImpactSeverity > 2
                            ? "danger"
                            : "primary"
                        }
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap();
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              txHash: undefined,
                            });
                            onPresentConfirmModal();
                          }
                        }}
                        width="48%"
                        id="swap-button"
                        disabled={
                          !isValid ||
                          approval !== ApprovalState.APPROVED ||
                          (priceImpactSeverity > 3 && !isExpertMode)
                        }
                      >
                        {priceImpactSeverity > 3 && !isExpertMode
                          ? t("Price Impact High")
                          : priceImpactSeverity > 2
                            ? t("Swap Anyway")
                            : t("Swap")}
                      </Button>
                    </RowBetween>
                  ) : (
                    <Button
                      variant={
                        isValid && priceImpactSeverity > 2 && !swapCallbackError
                          ? "danger"
                          : "primary"
                      }
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap();
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            txHash: undefined,
                          });
                          onPresentConfirmModal();
                        }
                      }}
                      id="swap-button"
                      width="100%"
                      disabled={
                        !isValid ||
                        (priceImpactSeverity > 3 && !isExpertMode) ||
                        !!swapCallbackError
                      }
                    >
                      {swapInputError ||
                        (priceImpactSeverity > 3 && !isExpertMode
                          ? t("Price Impact Too High")
                          : priceImpactSeverity > 2
                            ? t("Swap Anyway")
                            : t("Swap"))}
                    </Button>
                  )}
                  {showApproveFlow && (
                    <Column style={{ marginTop: "1rem" }}>
                      <ProgressSteps
                        steps={[approval === ApprovalState.APPROVED]}
                      />
                    </Column>
                  )}
                  {isExpertMode && swapErrorMessage ? (
                    <SwapCallbackError error={swapErrorMessage} />
                  ) : null}
                </Box>
              </Wrapper>
            </AppBody>
            {!swapIsUnsupported ? (
              trade && <AdvancedSwapDetailsDropdown trade={trade} />
            ) : (
              <UnsupportedCurrencyFooter
                currencies={[currencies.INPUT, currencies.OUTPUT]}
              />
            )}
          </StyledInputCurrencyWrapper>
        </StyledSwapContainer>
        {/* {isChartExpanded && (
              <Box display={['none', null, null, 'block']} width="100%" height="100%">
                <Footer variant="side" />
              </Box>
            )} */}
      </Flex>
    </Flex>
  );
}
