export enum CurrencyModalView {
  search,
  manage,
  importToken,
  importList,
}

export interface CustomTokenData {
  address: string;
  chainId: number;
  createdAt: string;
  decimals: number;
  description: string;
  id: string;
  logo: string;
  name: string;
  projectLink: string;
  symbol: string;
}

export default CurrencyModalView;
