import { parseUnits } from "@ethersproject/units";
import {
  Currency,
  CurrencyAmount,
  ETHER,
  JSBI,
  Token,
  TokenAmount,
  Trade,
} from "fortcake-sdk";
import { ParsedQs } from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useENS from "../../hooks/ENS/useENS";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useCurrency } from "../../hooks/Tokens";
import { useTradeExactIn, useTradeExactOut } from "../../hooks/Trades";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { useTranslation } from "../../contexts/Localization";
import { isAddress } from "../../utils";
import { computeSlippageAdjustedAmounts } from "../../utils/prices";
import getLpAddress from "../../utils/getLpAddress";
import { getTokenAddress } from "../../views/Swap/components/Chart/utils";
import { AppDispatch, AppState } from "../index";
import { useCurrencyBalances } from "../wallet/hooks";
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
  updateDerivedPairData,
  updatePairData,
} from "./actions";
import { SwapState } from "./reducer";
import { useUserSlippageTolerance } from "../user/hooks";
import fetchPairPriceData from "./fetch/fetchPairPriceData";
import {
  normalizeChartData,
  normalizeDerivedChartData,
  normalizeDerivedPairDataByActiveToken,
  normalizePairDataByActiveToken,
} from "./normalizers";
import { PairDataTimeWindowEnum, PairPricesNormalized } from "./types";
import { derivedPairByDataIdSelector, pairByDataIdSelector } from "./selectors";
import { DEFAULT_INPUT_CURRENCY, DEFAULT_OUTPUT_CURRENCY } from "./constants";
import fetchDerivedPriceData from "./fetch/fetchDerivedPriceData";
import { pairHasEnoughLiquidity } from "./fetch/utils";

export function useSwapState(): AppState["swap"] {
  return useSelector<AppState, AppState["swap"]>((state) => state.swap);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === ETHER
                ? "BNB"
                : "",
        })
      );
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

// try to parse a user entered amount for a given token
export function tryParseAmount(
  value?: string,
  currency?: Currency
): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f", // v2 factory
  "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a", // v2 router 01
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D", // v2 router 02
];

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some((token) => token.address === checksummedAddress) ||
    trade.route.pairs.some(
      (pair) => pair.liquidityToken.address === checksummedAddress
    )
  );
}

// Get swap price for single token disregarding slippage and price impact
export function useSingleTokenSwapInfo(): { [key: string]: number } {
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  const token0Address = getTokenAddress(inputCurrencyId);
  const token1Address = getTokenAddress(outputCurrencyId);

  const parsedAmount = tryParseAmount("1", inputCurrency ?? undefined);

  const bestTradeExactIn = useTradeExactIn(
    parsedAmount,
    outputCurrency ?? undefined
  );
  if (!inputCurrency || !outputCurrency || !bestTradeExactIn) {
    return {};
  }

  const inputTokenPrice = parseFloat(
    bestTradeExactIn?.executionPrice?.toSignificant(6)
  );
  const outputTokenPrice = 1 / inputTokenPrice;

  return {
    [token0Address]: inputTokenPrice,
    [token1Address]: outputTokenPrice,
  };
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount };
  parsedAmount: CurrencyAmount | undefined;
  v2Trade: Trade | undefined;
  inputError?: string;
} {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  const recipientLookup = useENS(recipient ?? undefined);
  const to: string | null =
    (recipient === null ? account : recipientLookup.address) ?? null;

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = tryParseAmount(
    typedValue,
    (isExactIn ? inputCurrency : outputCurrency) ?? undefined
  );

  const bestTradeExactIn = useTradeExactIn(
    isExactIn ? parsedAmount : undefined,
    outputCurrency ?? undefined
  );
  const bestTradeExactOut = useTradeExactOut(
    inputCurrency ?? undefined,
    !isExactIn ? parsedAmount : undefined
  );

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  };

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  let inputError: string | undefined;
  if (!account) {
    inputError = t("Connect Wallet");
  }

  if (!parsedAmount) {
    inputError = inputError ?? t("Enter an amount");
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? t("Select a token");
  }

  const formattedTo = isAddress(to);
  if (!to || !formattedTo) {
    inputError = inputError ?? t("Enter a recipient");
  } else if (
    BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
    (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
    (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
  ) {
    inputError = inputError ?? t("Invalid recipient");
  }

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    v2Trade &&
    allowedSlippage &&
    computeSlippageAdjustedAmounts(v2Trade, allowedSlippage);

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
  ];

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = t("Insufficient %symbol% balance", {
      symbol: amountIn.currency.symbol as string,
    });
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    inputError,
  };
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === "BNB") return "BNB";
    if (valid === false) return "BNB";
  }
  return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
  // eslint-disable-next-line no-restricted-globals
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== "string") return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency =
    parseCurrencyFromURLParameter(parsedQs.inputCurrency) ||
    DEFAULT_INPUT_CURRENCY;
  let outputCurrency =
    parseCurrencyFromURLParameter(parsedQs.outputCurrency) ||
    DEFAULT_OUTPUT_CURRENCY;
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === "string") {
      inputCurrency = "";
    } else {
      outputCurrency = "";
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    pairDataById: {},
    derivedPairDataById: {},
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | {
      inputCurrencyId: string | undefined;
      outputCurrencyId: string | undefined;
    }
  | undefined {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const parsedQs = useParsedQueryString();
  const [result, setResult] = useState<
    | {
        inputCurrencyId: string | undefined;
        outputCurrencyId: string | undefined;
      }
    | undefined
  >();

  useEffect(() => {
    if (!chainId) return;
    const parsed = queryParametersToSwapState(parsedQs);
    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: null,
      })
    );

    setResult({
      inputCurrencyId: parsed[Field.INPUT].currencyId,
      outputCurrencyId: parsed[Field.OUTPUT].currencyId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId]);

  return result;
}

type useFetchPairPricesParams = {
  token0Address: string;
  token1Address: string;
  timeWindow: PairDataTimeWindowEnum;
  currentSwapPrice: {
    [key: string]: number;
  };
};

export const useFetchPairPrices = ({
  token0Address,
  token1Address,
  timeWindow,
  currentSwapPrice,
}: useFetchPairPricesParams) => {
  const [pairId, setPairId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const pairData = useSelector(pairByDataIdSelector({ pairId, timeWindow }));
  const derivedPairData = useSelector(
    derivedPairByDataIdSelector({ pairId, timeWindow })
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDerivedData = async () => {
      console.info(
        "[Price Chart]: Not possible to retrieve price data from single pool, trying to fetch derived prices"
      );
      try {
        // Try to get at least derived data for chart
        // This is used when there is no direct data for pool
        // i.e. when multihops are necessary
        const derivedData = await fetchDerivedPriceData(
          token0Address,
          token1Address,
          timeWindow
        );
        if (derivedData) {
          const normalizedDerivedData = normalizeDerivedChartData(derivedData);
          dispatch(
            updateDerivedPairData({
              pairData: normalizedDerivedData,
              pairId,
              timeWindow,
            })
          );
        } else {
          dispatch(updateDerivedPairData({ pairData: [], pairId, timeWindow }));
        }
      } catch (error) {
        console.error("Failed to fetch derived prices for chart", error);
        dispatch(updateDerivedPairData({ pairData: [], pairId, timeWindow }));
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAndUpdatePairPrice = async () => {
      setIsLoading(true);
      const { data } = await fetchPairPriceData({ pairId, timeWindow });
      if (data) {
        // Find out if Liquidity Pool has enough liquidity
        // low liquidity pool might mean that the price is incorrect
        // in that case try to get derived price
        const hasEnoughLiquidity = pairHasEnoughLiquidity(data, timeWindow);
        const newPairData = normalizeChartData(data, timeWindow) || [];
        if (newPairData.length > 0 && hasEnoughLiquidity) {
          dispatch(
            updatePairData({ pairData: newPairData, pairId, timeWindow })
          );
          setIsLoading(false);
        } else {
          console.info(`[Price Chart]: Liquidity too low for ${pairId}`);
          dispatch(updatePairData({ pairData: [], pairId, timeWindow }));
          fetchDerivedData();
        }
      } else {
        dispatch(updatePairData({ pairData: [], pairId, timeWindow }));
        fetchDerivedData();
      }
    };

    if (!pairData && !derivedPairData && pairId && !isLoading) {
      fetchAndUpdatePairPrice();
    }
  }, [
    pairId,
    timeWindow,
    pairData,
    currentSwapPrice,
    token0Address,
    token1Address,
    derivedPairData,
    dispatch,
    isLoading,
  ]);

  useEffect(() => {
    const updatePairId = () => {
      try {
        const pairAddress = getLpAddress(
          token0Address,
          token1Address
        )?.toLowerCase();
        if (pairAddress !== pairId) {
          setPairId(pairAddress as string);
        }
      } catch (error) {
        setPairId("");
      }
    };

    updatePairId();
  }, [token0Address, token1Address, pairId]);

  const normalizedPairData = useMemo(
    () =>
      normalizePairDataByActiveToken({ activeToken: token0Address, pairData }),
    [token0Address, pairData]
  );

  const normalizedDerivedPairData = useMemo(
    () =>
      normalizeDerivedPairDataByActiveToken({
        activeToken: token0Address,
        pairData: derivedPairData,
      }),
    [token0Address, derivedPairData]
  );

  const hasSwapPrice = currentSwapPrice && currentSwapPrice[token0Address] > 0;

  const normalizedPairDataWithCurrentSwapPrice =
    normalizedPairData?.length > 0 && hasSwapPrice
      ? [
          ...normalizedPairData,
          { time: new Date(), value: currentSwapPrice[token0Address] },
        ]
      : normalizedPairData;

  const normalizedDerivedPairDataWithCurrentSwapPrice =
    normalizedDerivedPairData?.length > 0 && hasSwapPrice
      ? [
          ...normalizedDerivedPairData,
          { time: new Date(), value: currentSwapPrice[token0Address] },
        ]
      : normalizedDerivedPairData;

  const hasNoDirectData =
    normalizedPairDataWithCurrentSwapPrice &&
    normalizedPairDataWithCurrentSwapPrice?.length === 0;
  const hasNoDerivedData =
    normalizedDerivedPairDataWithCurrentSwapPrice &&
    normalizedDerivedPairDataWithCurrentSwapPrice?.length === 0;

  // undefined is used for loading
  // let pairPrices: PairPricesNormalized  = hasNoDirectData && hasNoDerivedData ? [] : undefined;
  let pairPrices: PairPricesNormalized = [];
  if (
    normalizedPairDataWithCurrentSwapPrice &&
    normalizedPairDataWithCurrentSwapPrice?.length > 0
  ) {
    pairPrices = normalizedPairDataWithCurrentSwapPrice;
  } else if (
    normalizedDerivedPairDataWithCurrentSwapPrice &&
    normalizedDerivedPairDataWithCurrentSwapPrice?.length > 0
  ) {
    pairPrices = normalizedDerivedPairDataWithCurrentSwapPrice;
  }
  return { pairPrices, pairId };
};
