import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../state";
import { State, Game, GamesState } from "../types";
import {
  Categories,
  ChainProps,
  GameProps,
  Platforms,
} from "../../views/Games/components/types";
import { fetchGames, fetchGamePrices } from ".";
import axios from "axios";
import useToast from "../../hooks/useToast";
import { CustomTokenData } from "../../components/SearchModal/types";

const deserializeGame = (game: Game): GameProps => {
  const chain = {
    chain: game.chain,
    address: game.address,
  };

  const gameDetails = Object.keys(game)
    .filter((key) => key !== "chain" && key !== "address")
    .reduce(
      (accumulator, current) => {
        const gameProperty = {
          [current]: game[current as keyof Game],
        };
        return { ...accumulator, ...gameProperty };
      },
      {} as Omit<GameProps, "chain">
    );

  return {
    ...gameDetails,
    chain: [{ ...chain } as ChainProps],
  };
};

const createGameList = (gameList: Game[], allPrices: GamesState["prices"]) => {
  const deserializedGames = gameList.reduce<GameProps[]>((arr, game) => {
    const symbolOnDiffChain = arr.findIndex(
      ({ symbol }) => symbol === game.symbol
    );
    if (symbolOnDiffChain >= 0) {
      arr[symbolOnDiffChain].chain.push({
        chain: game.chain,
        address: game.address as string,
      });
      return arr;
    }
    const deserializedGame = deserializeGame(game);

    const found = allPrices.find(({ address }) => {
      return address.toUpperCase() === game.address?.toUpperCase();
    });

    deserializedGame.price =
      found !== undefined ? found.price : allPrices[0].price;

    arr.push(deserializedGame);

    return arr;
  }, []);

  return deserializedGames.sort((a, b) => b.votes - a.votes);
};

export const useFetchGames = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    /**
     * TODO - UNCOMMENT
     */
    // dispatch(fetchGames())
  }, [dispatch]);
};

export const useFetchGamePrices = () => {
  const { data, isLoading } = useSelector((state: State) => state.games);
  const dispatch = useAppDispatch();
  useEffect(() => {
    /**
     * TODO - UNCOMMENT
     */
    // console.log("---ABCD---");
    // let timer: any
    // if (data.length && !isLoading) {
    //     dispatch(fetchGamePrices())
    //     timer = setInterval(() => {
    //         dispatch(fetchGamePrices())
    //     }, 300000)
    // }
    // if (timer > 0)
    //     clearInterval(timer)
  }, [data.length, dispatch, isLoading]);
};

export const useGames = () => {
  const { toastError } = useToast();
  const { userDataLoaded, data, prices, isLoading } = useSelector(
    (state: State) => state.games
  );
  const [allGames, setAllGames] = useState<GameProps[]>([]);
  useEffect(() => {
    (async () => {
      let customTokenList: Game[] = [];
      let customTokenPrice: GamesState["prices"] = [];
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tokens`
        );
        if (response.status === 200) {
          const tokenList = response.data;
          customTokenList = tokenList.map((token: CustomTokenData) => ({
            title: token.name,
            subtitle: token.description,
            logo: token.logo,
            cta: token.projectLink,
            symbol: token.symbol,
            votes: 0,
            chain: "BSC",
            address: token.address,
            category: Categories.ACTION,
            twitter: undefined,
            discord: undefined,
            telegram: undefined,
            platform: [Platforms.WEB],
          }));
          customTokenPrice = tokenList.map((token: CustomTokenData) => ({
            address: token.address,
            price: {
              bnb: 0.0,
              usd: 0.0,
            },
          }));
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          toastError(error.response.data.error || "Failed to get tokens.");
        } else {
          toastError("An error occurred while gettting the token list.");
        }
      }
      const gamesWithPrices = createGameList(
        [...data, ...customTokenList],
        [...prices, ...customTokenPrice]
      );
      setAllGames(gamesWithPrices);
    })();
  }, [data, prices, toastError]);

  return {
    isLoading,
    userDataLoaded,
    data: allGames,
  };
};
