import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Currency, ETHER, Token } from "fortcake-sdk";
import {
  Flex,
  CardBody,
  useMatchBreakpoints,
  Input,
  CloseIcon,
} from "fortcake-uikit-v2";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/Localization";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state";
import { AutoColumn } from "../../components/Layout/Column";
import { AppHeader, AppBody } from "../../components/App";
import { resetMintState } from "../../state/mint/actions";
import { currencyId } from "../../utils/currencyId";
import { FixedSizeList } from "react-window";
import useDebounce from "../../hooks/useDebounce";
import { useAllTokens, useToken } from "../../hooks/Tokens";
import useTokenComparator from "../../components/SearchModal/sorting";
import {
  filterTokens,
  useSortedTokensByQuery,
} from "../../components/SearchModal/filtering";
import { isAddress } from "../../utils";
import getTokenLogoURL from "../../utils/getTokenLogoURL";
import axios from "axios";
import useToast from "../../hooks/useToast";

interface CustomTokenData {
  name: string;
  symbol: string;
  decimals: string;
  chainId: string;
  description: string;
  projectLink: string;
  logoUrl: string | null;
  tokenLogo: File | null;
}

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB

export default function NewToken() {
  const { toastSuccess, toastError } = useToast();
  const [formData, setFormData] = useState<CustomTokenData>({
    name: "",
    symbol: "",
    decimals: "",
    description: "",
    chainId: "",
    projectLink: "",
    logoUrl: null,
    tokenLogo: null,
  });
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("File size exceeds the 1MB limit.");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        tokenLogo: file,
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      setError(null); // Clear any previous errors
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!searchToken?.address) {
      toastError("Please check if the token address is correct.");
      return;
    }

    if (!formData.tokenLogo) {
      toastError("Please upload a logo image.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("address", searchToken.address);
    formDataToSend.append("symbol", formData.symbol);
    formDataToSend.append("decimals", formData.decimals);
    formDataToSend.append("chainId", formData.chainId);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("projectLink", formData.projectLink);
    formDataToSend.append("tokenLogo", formData.tokenLogo);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/new`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toastSuccess("Token submitted successfully!");
        setFormData({
          name: "",
          symbol: "",
          decimals: "",
          chainId: "",
          description: "",
          projectLink: "",
          logoUrl: null,
          tokenLogo: null,
        });
        setSearchQuery("");
        setImagePreview(null);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        toastError(error.response.data.error || "Failed to add token.");
      } else {
        toastError("An error occurred while submitting the form.");
      }
    }
  };

  const { currencyIdA, currencyIdB } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();

  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {
      const newCurrencyIdA = currencyId(currencyA_);
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/${currencyIdB}/${currencyIdA}`);
        // history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else if (currencyIdB) {
        navigate(`/add/${newCurrencyIdA}/${currencyIdB}`);
        // history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      } else {
        navigate(`/add/${newCurrencyIdA}`);
        // history.push(`/add/${newCurrencyIdA}`)
      }
    },
    [currencyIdB, currencyIdA, navigate]
  );

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      handleCurrencyASelect(currency);
    },
    [handleCurrencyASelect]
  );

  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);

  const [invertSearchOrder] = useState<boolean>(false);

  const allTokens = useAllTokens();

  const searchToken = useToken(debouncedQuery);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery);
  }, [allTokens, debouncedQuery]);
  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator);
  }, [filteredTokens, tokenComparator]);

  const filteredSortedTokens = useSortedTokensByQuery(
    sortedTokens,
    debouncedQuery
  );

  const inputRef = useRef<HTMLInputElement>();

  const handleInput = useCallback((event: any) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const deleteAddress = () => {
    setSearchQuery("");
    setFormData({
      name: "",
      symbol: "",
      decimals: "",
      chainId: "",
      description: "",
      projectLink: "",
      logoUrl: null,
      tokenLogo: null,
    });
    setImagePreview(null);
  };

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === "bnb") {
          handleCurrencySelect(ETHER);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery]
  );

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      dispatch(resetMintState());
    }
  }, [dispatch, currencyIdA, currencyIdB]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchToken) {
      const logoUrl = getTokenLogoURL(searchToken.address);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...(searchToken.name && { name: searchToken.name }),
        ...(searchToken.symbol && { symbol: searchToken.symbol }),
        ...(searchToken.decimals && {
          decimals: searchToken.decimals.toString(),
        }),
        ...(searchToken.chainId && {
          chainId: searchToken.chainId.toString(),
        }),
        ...(searchToken.projectLink && {
          projectLink: searchToken.projectLink,
        }),
        ...(logoUrl && {
          logoUrl: logoUrl,
        }),
      }));
    }
  }, [searchToken]);

  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      position="relative"
      style={{ minHeight: isMobile || isTablet ? "80vh" : "100vh" }}
    >
      <AppBody>
        <AppHeader
          title={t("Add Token")}
          subtitle={t("Add new custom token")}
          helper={t(
            "Liquidity providers earn a 0.17% trading fee on all trades made for that token pair, proportional to their share of the liquidity pool."
          )}
        />
        <CardBody>
          <form onSubmit={handleSubmit} className="space-y-4">
            <AutoColumn gap="16px">
              <div className="flex flex-col gap-2">
                <label>Token Address</label>
                <div className="relative">
                  <Input
                    require
                    id="token-search-input"
                    placeholder={""}
                    scale="lg"
                    autoComplete="off"
                    value={searchQuery}
                    ref={inputRef as React.RefObject<HTMLInputElement>}
                    onChange={handleInput}
                    onKeyDown={handleEnter}
                  />
                  {searchQuery && (
                    <div className="absolute top-2 right-2 rounded-full border cursor-pointer">
                      <CloseIcon width="28px" onClick={deleteAddress} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label>Name</label>
                <Input
                  id="name"
                  Name="name"
                  placeholder={""}
                  scale="lg"
                  autoComplete="off"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Symbol</label>
                <Input
                  id="symbol"
                  name="symbol"
                  placeholder={""}
                  scale="lg"
                  autoComplete="off"
                  value={formData.symbol}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Description</label>
                <textarea
                  className="rounded-xl bg-[#372F47] text-[#F4EEFF] p-4 w-full border border-[#262130] outline-0"
                  id="description"
                  name="description"
                  placeholder={""}
                  rows={4}
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Decimals</label>
                <Input
                  id="decimals"
                  name="decimals"
                  placeholder={""}
                  scale="lg"
                  autoComplete="off"
                  value={formData.decimals}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>ChainId</label>
                <Input
                  id="chainId"
                  name="chainId"
                  placeholder={""}
                  scale="lg"
                  autoComplete="off"
                  value={formData.chainId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Project Link</label>
                <Input
                  id="projectLink"
                  name="projectLink"
                  placeholder={""}
                  scale="lg"
                  autoComplete="off"
                  value={formData.projectLink}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Token Logo</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                {(imagePreview || formData?.logoUrl) && (
                  <img
                    src={imagePreview || (formData.logoUrl as string)}
                    alt="Preview"
                    className="mt-4 w-24 h-24 object-cover rounded border"
                  />
                )}
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
              <button
                type="submit"
                className="w-full bg-[#f18d9e] text-white py-4 px-4 rounded-2xl hover:bg-pink-500 font-bold"
              >
                Submit
              </button>
            </AutoColumn>
          </form>
        </CardBody>
      </AppBody>
    </Flex>
  );
}
