import React from "react";
import styled from "styled-components";
import { Card } from "fortcake-uikit-v2";

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 536px;
  width: 100%;
  z-index: 1;
`;

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>;
}
